<template>
  <div>
    <div class="block">
      <h3>О сайте</h3>
      <div class="main-message-box">
        Всем, всем привет с Вами Кейн и... и сайт находится в разработке *(<br>
        Когда он будет запущен я сказать не могу, так как это зависит от наличия свободного времени.<br>
        Я оставлю эту форму здесь, напиши в него свой email и когда сайт будет готов, ты обязательно об этом узнаешь ^_^
      </div>
      <Subscription/>
    </div>
    <div class="block">
      <h3>Сервисы</h3>
      <div class="main-message-box">
        Пока сайта нет, можешь посмотреть, что я творю в следующих сервисах
      </div>
      <div class="service-box">
        <div class="element"><a href="http://www.youtube.com/KeynTR" target="_blank"><span>YouTube канал</span></a></div>
        <div class="element"><a href="https://www.twitch.tv/keyntr" target="_blank"><span>Twitch канал</span></a></div>
        <div class="element"><a href="https://zen.yandex.ru/id/6234c28c365c026c3020f3bb" target="_blank"><span>Яндекс.Дзен</span></a></div>
      </div>
      <div class="service-box">
        <div class="element"><a href="https://discord.gg/JwAcAPtWpq" target="_blank"><span>Discord</span></a></div>
        <div class="element"><a href="https://vk.com/keyntr" target="_blank"><span>ВКонтакте</span></a></div>
      </div>
      <div class="service-box">
        <div class="element"><a href="https://t.me/KeynTR" target="_blank"><span>Телеграм</span></a></div>
        <div class="element"><a href="https://http://t.me/KeynTRChat" target="_blank"><span>Телеграм ЧАТ</span></a></div>
      </div>
      <div class="service-box">
        <div class="element"><a href="https://goodgame.ru/channel/KeynTR/" target="_blank"><span>GoodGame канал</span></a></div>
        <div class="element"><a href="https://trovo.live/KeynTR" target="_blank"><span>Trovo канал</span></a></div>
        <div class="element"><a href="https://wasd.tv/keyntr" target="_blank"><span>WASD канал</span></a></div>
      </div>
      <div class="service-box">
        <div class="element"><a href="https://rutube.ru/channel/1368202/"><span>RuTube</span></a></div>
      </div>
      <div class="main-message-box">
        Также можешь добавить меня в друзья в
      </div>
      <div class="service-box">
        <div class="element"><a href="https://steamcommunity.com/id/keyntr/" target="_blank"><span>Steam</span></a></div>
      </div>
      <div class="main-message-box">
        Ты можешь поддержать меня материально, спасибо
      </div>
      <div class="service-box">
        <div class="element"><a href="http://www.donationalerts.ru/r/keyntr" target="_blank"><span>Donationalerts</span></a></div>
      </div>
    </div>
    <div class="block">
      <h3>Гостевая книга</h3>
      <SendCommentForm
          :entity=guestBook.entity
          :entityId=guestBook.id
      />
      <template v-if="commentList.length">
        <template v-for="comment in commentList" :key="comment.id">
          <CommentBlock
              :field=comment
              class="in-developing"
          />
        </template>
      </template>
      <template v-else>
        <div class="zero-comments">
          Комментариев пока нет, будь первым ^_^
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Subscription from '@/components/mailings/Subscription.vue';
import SendCommentForm from "@/components/comments/SendCommentForm";
import CommentBlock from "@/components/comments/CommentBlock";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'About',
  components: {
    Subscription,
    SendCommentForm,
    CommentBlock,
  },
  data() {
    return {
      guestBook: {
        entity: 'guestBook',
        id: 1
      },
    }
  },
  computed: {
    ...mapGetters({
      commentList: 'Comments/getCommentList',
    }),
  },
  created() {
    this.fetchCommentList({
      entity:   this.guestBook.entity,
      entityId: this.guestBook.id,
    });
  },
  methods: {
    ...mapActions({
      fetchCommentList: 'Comments/fetchCommentList',
    }),
  },
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 30px;
  text-transform: uppercase;
  margin: 25px 0 25px 0;
  font-weight: 400;
}

.main-message-box {
  font-size: 22px;
  line-height: 40px;
  letter-spacing: 5px;
  margin-top: 20px;
}

.service-box {
  display: flex;
  font-size: 25px;
  margin-top: 25px;

  .element {
    width: 30%;
  }
}

.zero-comments {
  font-size: 22px;
  line-height: 40px;
  letter-spacing: 5px;
  margin-top: 20px;
}
</style>
