<template>
  <div class="subscription-form">
    <input type="text" placeholder="твой email" v-model="email"> <button @click="sendSubscriptionEmail()">Стать одни из первых посетителей сайта</button>
  </div>
</template>

<script>
import alertMixin from "@/mixins/alertMixin";
import validate from "@/mixins/validate";
import axiosInstance from "@/axiosInstance";

export default {
  name: 'Subscription',
  mixins: [
    alertMixin,
    validate,
  ],
  data() {
    return {
      email: '',
    }
  },
  methods: {
    async sendSubscriptionEmail() {
      let errorMessage = null;

      if (!this.email)
        errorMessage = 'Необходимо заполнить поле email, чтобы подписатья';
      else if (!this.validateEmail(this.email))
        errorMessage = `${this.email} - это не похоже на email...`;

      if (errorMessage) {
        this.showAlert(errorMessage, 5000, '#ff0000');
      } else {
        await axiosInstance.post('subscription/add', {payload: this.email}).then((response) => {
          if (response.data) {
            this.showAlert('Вы успешно подписаны', 5000);
            this.email = '';
          } else {
            this.showAlert('Вы уже подписаны', 5000, '#ff0000');
          }
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .subscription-form {
    input {
      width: 30%;
    }
  }
</style>
